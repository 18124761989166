<template>
  <div class="bg">
    <div class="imgBox">
      <img
        class="bgimg"
        src="../../assets/image/bg.png"
      >
    </div>

    <div class="center">
      <div class="container">
        <div class="login">
          <a-menu
            v-model="current"
            mode="horizontal"
          >
            <a-menu-item key="login">
              账户登录
            </a-menu-item>
            <a-menu-item key="CAlogin">
              CA登录
            </a-menu-item>
          </a-menu>
          <div
            v-if="current[0] === 'CAlogin'"
            class="psw"
          >
            <div class="ukey">
              <img src="../../assets/image/ukey.png">
              请插入颁发的UKEY，一键登录
            </div>
            <!-- <a-input-password
              @keyup.enter="login"
              v-model="form.CApassword"
              placeholder="请输入CA证书的密码"
              style="width: 270px;margin: 10px auto;"
            /> -->
            <div class="kong" />
            <a-button
              type="primary"
              @click="login"
            >
              CA登录
            </a-button>
            <div class="tip">
              <span>首次登录需安装CA驱动程序，<a
                href="https://download.bqpoint.com/download/downloaddetail.html?SourceFrom=Ztb&ZtbSoftXiaQuCode=010501&ZtbSoftType=DR"
                target="_blank"
              >下载驱动</a>，安装后请使用IE浏览器登录，<a
                href="/api/insure/index/download?type=manual"
                target="_blank"
              >操作手册</a><br>
                点击无反应，请点此<a
                  href="https://zhidao.bqpoint.com/epointknow2/bqepointknowquestion.html?producttype=1&platformguid=f2797119-c3c5-4991-9fe8-f0a92eee0ad2&areacode=320000&wd=%E7%BC%93%E5%AD%98&QuestionGuid=a6349467-554c-4e42-a0f6-7bc2d26d7991"
                  target="_blank"
                >清理ie缓存</a><br><a
                  href="https://zhidao.bqpoint.com/epointknow2/bqepointknowquestion.html?producttype=1&platformguid=f2797119-c3c5-4991-9fe8-f0a92eee0ad2&areacode=320000"
                  target="_blank"
                >常见问题解答</a>
                <!-- <span @click="regis">用户注册</span> -->
              </span>
            </div>
            <!-- <div class="regFor">
            <div>检测到您登录为非颁发的证书，点击确认进入人工审核</div>
          </div> -->
          </div>
          <div
            v-if="current[0] === 'login'"
            class="psw"
          >
            <a-input
              v-model="form.phoneNum"
              placeholder="请输入您的手机号码"
              style="width: 270px;margin: 5px auto;"
            />
            <a-input-password
              @keyup.enter="login"
              v-model="form.password"
              placeholder="请输入您的登录密码"
              style="width: 270px;margin: 5px auto;"
            />
            <a
              class="pswLogin"
              @click="toPhoneLogin"
            >手机号码登陆</a>
            <a-button
              type="primary"
              @click="login"
            >
              立即登录
            </a-button>
            <div class="regFor">
              <span @click="forgot">忘记密码？</span>
              <span @click="regis">用户注册</span>
            </div>
          </div>
          <div
            v-if="current[0] === 'phone'"
            class="psw"
          >
            <a-input
              v-model="form.phoneNum"
              placeholder="请输入您的手机号码"
              style="width: 270px;margin: 5px auto;"
            />
            <div
              class="phoneCode"
              style="margin: 5px auto"
            >
              <a-input
                @keyup.enter="login"
                v-model="form.code"
                placeholder="请输入验证码"
                style="width: 270px;"
              />
              <img
                :src="codeImg"
                alt=""
                @click="getImgcode"
              >
            </div>
            <div
              class="phoneCode"
              style="margin: 5px auto"
            >
              <a-input
                @keyup.enter="login"
                v-model="form.phCode"
                placeholder="请输入短信验证码"
                style="width: 270px;"
              />
              <a
                v-if="isTime"
                class="phcode"
                @click="getCode"
              >获取验证码</a>
              <span v-else>{{ time }}s重新获取</span>
            </div>
            <a
              class="pswLogin"
              @click="toMsgLogin"
            >账号密码登陆</a>
            <a-button
              type="primary"
              @click="login"
            >
              立即登录
            </a-button>
            <div class="regFor">
              <span @click="forgot">忘记密码？</span>
              <span @click="regis">用户注册</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginCA, login, loginCode } from '@/api/login/register.js'; // , cetRandom
import { graphicsCode, smsCode } from '@/api/code.js';
import { info } from '@/api/user/user.js';
// import { checkYSTService } from '@/assets/script/CAjs/caLogin.js';
import { ReadCert } from '@/utils/CAlogin.js';
import { Modal } from 'ant-design-vue';
// import { h } from 'vue';
const querystring = require('querystring');

export default {
  name: 'Login',
  components: {

  },
  inject: [ 'reload' ],
  data() {
    return {
      form: {},
      current: [ 'CAlogin' ],
      codeImg: '',
      timer: '',
      isTime: true,
      time: 10,
    };
  },
  mounted() {
    // this.isIE();
    this.info();
    this.getImgcode();
  },
  methods: {
    isIE() {
      const ie11 = (() => 'ActiveXObject' in window)();
      console.log(ie11);
      if (!ie11) {
        Modal.info({
          title: '提示',
          okText: '取消',
          content: '系统检测到您正在使用非IE浏览器，因系统使用的部分第三方控件不支持非IE浏览器，为保障您的正常操作，建议使用IE浏览器！',
          onOk() {
            console.log('ok');
          },
        });
      }
    },
    getCode() {
      this.timer = '';
      if (this.form.code !== '' && this.form.phoneNum !== '' && !!this.form.code) {
        smsCode({ source: 'ACC_USER_LOGIN', checkCode: this.form.code, phoneNum: this.form.phoneNum }).then(res => {
          this.isCode = res.data;
          if (res.data.status === 'FAIL') {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning('手机号码和图形验证码不为空');
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    getImgcode() {
      graphicsCode({ source: 'ACC_USER_LOGIN' }).then(res => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    toPhoneLogin() {
      this.$set(this.current, 0, 'phone');
    },
    toMsgLogin() {
      this.$set(this.current, 0, 'login');
    },
    info() {
      info().then(res => {
        if (res.data.status === 'SUCCESS') {
          if (window.location.search !== '') {
            const packageId = querystring.parse(window.location.search.split('?')[1]).packageId;
            if (packageId !== '') {
              sessionStorage.setItem('projectSectionId', JSON.stringify(packageId));
              this.$router.push({ name: 'Project', query: { count: 2 } });
            }
          }
        }
      });
    },
    login() {
      if (this.current[0] === 'login') {
        login({ phoneNum: this.form.phoneNum, pwd: this.form.password }).then(res => {
          if (res.data.status === 'FAIL') {
            this.$message.warning(res.data.errorMsg);
          } else {
            sessionStorage.setItem('telephone', JSON.stringify(this.form.phoneNum));
            localStorage.setItem('token', res.data.data.token);
            this.reload();
            if (window.location.search !== '') {
              // const packageId = querystring.parse(window.location.search.split('?')[1]).packageId;
              // sessionStorage.setItem('projectSectionId', JSON.stringify(packageId));
              // this.$router.push({ name: 'Project', query: { count: 2 } });
              // this.$router.push({ path: '/orderDetail', query: { count: 2 } });
            } else {
              // this.$router.push({ name: 'Project' });
              this.$router.push('/insure/project/view');
            }
          }
        });
      } else if (this.current[0] === 'CAlogin') {
        loginCA({ data: ReadCert() }).then(res => {
          if (res.data.status === 'SUCCESS') {
            localStorage.setItem('token', res.data.data.token);
            this.$router.push('/insure/project/view');
          }
        });
        // cetRandom({ number: 5 }).then(res => {
        //   var signValue = 'MIIFcQYJKoZIhvcNAQcCoIIFYjCCBV4CAQExDDAKBggqgRzPVQGDETAZBgkqhkiG9w0BBwGgDAQKNkRBOEQyQUM0QaCCA/gwggP0MIIDmKADAgECAgh0wQCRALBzmjAMBggqgRzPVQGDdQUAMFIxCzAJBgNVBAYTAkNOMS8wLQYDVQQKDCZaaGVqaWFuZyBEaWdpdGFsIENlcnRpZmljYXRlIEF1dGhvcml0eTESMBAGA1UEAwwJWkpDQSBPQ0ExMB4XDTIwMDkyOTA5MDUxNVoXDTIxMDkyOTA5MDUxNVowgYUxCzAJBgNVBAYTAkNOMQ8wDQYDVQQIDAbmtZnmsZ8xLTArBgNVBAoMJOadreW3nuiejemDveenkeaKgOiCoeS7veaciemZkOWFrOWPuDE2MDQGA1UEAwwt5p2t5bee6J6N6YO956eR5oqA6IKh5Lu95pyJ6ZmQ5YWs5Y+4KOWJr+acrDEpMFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEN31hPf5HduH8lh0BONmRSMdRYDNXIC/UKr3qgFEVn3Towl02aefGJSxlvYvcKqiik/y/Br2saVnMsm1+OOiteqOCAiAwggIcMAwGA1UdEwQFMAMBAQAwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMAsGA1UdDwQEAwIAwDARBglghkgBhvhCAQEEBAMCAIAwHgYIKoEc0BQEAQMEEjkxMzMwMTAwNTk2NjM0MzAyTDAdBgcqgRyG7z0FBBLV472tyqG3qMjLyv3X1takyukwHwYDVR0jBBgwFoAUp9OxJJAgHT20VO5LNyhSka4saiIwgagGA1UdHwSBoDCBnTCBmqCBl6CBlIaBkWxkYXA6Ly9sZGFwLnpqY2EuY29tLmNuL0NOPVpKQ0EgT0NBMSxDTj1aSkNBIE9DQTEsIE9VPUNSTERpc3RyaWJ1dGVQb2ludHMsIG89empjYT9jZXJ0aWZpY2F0ZVJldm9jYXRpb25MaXN0P2Jhc2U/b2JqZWN0Y2xhc3M9Y1JMRGlzdHJpYnV0aW9uUG9pbnQwgaIGCCsGAQUFBwEBBIGVMIGSMIGPBggrBgEFBQcwAoaBgmxkYXA6Ly9sZGFwLnpqY2EuY29tLmNuL0NOPVpKQ0EgT0NBMSxDTj1aSkNBIE9DQTEsIE9VPWNBQ2VydGlmaWNhdGVzLCBvPXpqY2E/Y0FDZXJ0aWZpY2F0ZT9iYXNlP29iamVjdENsYXNzPWNlcnRpZmljYXRpb25BdXRob3JpdHkwHQYDVR0OBBYEFPVdeFOcDQg0Vxa1vvu7Cb0sac81MAwGCCqBHM9VAYN1BQADSAAwRQIhAPm1qwlx+i0wqKAAmgzF6koVoD0DSY2CT/fE7hImQtmwAiBqY44QNAOcPuDyJFEQWnj6/Jv+qbDtdXXdUN/BRkuW8DGCATIwggEuAgEBMF4wUjELMAkGA1UEBhMCQ04xLzAtBgNVBAoMJlpoZWppYW5nIERpZ2l0YWwgQ2VydGlmaWNhdGUgQXV0aG9yaXR5MRIwEAYDVQQDDAlaSkNBIE9DQTECCHTBAJEAsHOaMAoGCCqBHM9VAYMRoGkwGAYJKoZIhvcNAQkDMQsGCSqGSIb3DQEHATAcBgkqhkiG9w0BCQUxDxcNMjEwNjE5MDU0NDIzWjAvBgkqhkiG9w0BCQQxIgQgcXmUyVKVuCb9aAl26Enaptu52KiMUg2EfNuVZOxsYk4wCgYIKoEcz1UBg3UERjBEAiBi4rATWg6ba9Y4IB5ADwr10NdzHIYXOHX+HYmYJV9kxQIgX3K+DBrrQVTgHW8S0N+szENxCaQay3dIFpe9L5Z26xI='
        //   var source = '6DA8D2AC4A'
        //   CAlogin({signValue: signValue ,source: source}).then(res => {
        //     if (res.data.status === 'FAIL') {
        //       this.$message.warning(res.data.errorMsg)
        //     } else {
        //       this.$router.push({ name: 'Project' });
        //     }
        //   })
        // });
      } else if (this.current[0] === 'phone') {
        if (this.form.phCode === '' || !this.form.phCode) {
          return this.$message.warning('短信验证码不能为空');
        }
        const params = {
          phoneNum: this.form.phoneNum,
          phoneCode: this.form.phCode,
        };
        loginCode(params).then(res => {
          if (res.data.status === 'FAIL') {
            this.$message.warning(res.data.errorMsg);
          } else {
            sessionStorage.setItem('telephone', JSON.stringify(this.form.phoneNum));
            localStorage.setItem('token', res.data.data.token);
            this.reload();
            if (window.location.search !== '') {
              const packageId = querystring.parse(window.location.search.split('?')[1]).packageId;
              sessionStorage.setItem('projectSectionId', JSON.stringify(packageId));
              // this.$router.push({ name: 'Project', query: { count: 2 } });
              this.$router.push({ path: '/insure/project/view', query: { count: 2 } });
            } else {
              // this.$router.push({ name: 'Project' });
              this.$router.push('/insure/project/view');
            }
          }
        });
      }
    },
    forgot() {
      this.$router.push({ name: 'forgotPassword' });
    },
    regis() {
      this.$router.push({ name: 'register' });
    },
    clickLogo() {
      if (this.$route.path === '/login' || this.$route.path === '/insure/project/view') {
        return false;
      } else if (this.$route.path === '/forgotPassword' || this.$route.path === '/register') {
        this.$router.push({ name: 'login' });
      } else {
        this.$router.push({ name: 'Project' });
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding-top: 0;
}
.imgBox {
  position: relative;
  display: flex;
  justify-content: center;
}
.bgimg {
  background-color: #10284c;
  // height: 786px;
  height: 830px;
}
.center {
  margin: 0 auto;
}
.container {
  position: absolute;
  top: 36vh;
  right: 18vw;
  padding-bottom: 30px;
  width: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(12, 31, 47, 0.2);
  border-radius: 5px;
  .login {
    text-align: center;
    /deep/ .ant-menu-horizontal {
      width: 270px;
      margin: 15px auto;
    }
    /deep/ .ant-menu-item {
      padding: 0 31px;
    }
    .loginTitle {
      width: 270px;
      height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin: 10px auto;
      margin-top: 34px;
    }
    .psw {
      display: flex;
      flex-direction: column;
      /deep/ .ant-input {
        text-align: left;
        height: 42px;
        border: 1px solid #eaeaea;
        border-radius: 3px;
      }
      .phoneCode {
        position: relative;
        .phcode {
          position: absolute;
          width: 84px;
          transform: translate(-85px, 8px);
          cursor: pointer;
        }
        span {
          position: absolute;
          width: 90px;
          transform: translate(-90px, 8px);
          color: #aaa;
        }
        img {
          width: 84px;
          height: 27px;
          position: absolute;
          transform: translate(-90px, 8px);
          background: #fff;
          cursor: pointer;
        }
      }
      .pswLogin {
        text-align: right;
        margin-right: 30px;
      }
      .ukey {
        width: 270px;
        margin: 40px auto;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }
      .kong {
        height: 23px;
      }
      button {
        width: 270px;
        height: 44px;
        background: #007ce2;
        box-shadow: 0px 5px 8px 0px rgba(136, 189, 232, 0.3);
        border-radius: 3px;
        margin: 10px auto;
        margin-top: 20px;
      }
      .regFor {
        width: 270px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 7px;
          cursor: pointer;
        }
      }
      .tip {
        width: 270px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .down {
          cursor: pointer;
          color: #007ce2;
        }
      }
    }
  }
}
</style>
